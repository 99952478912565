import { Button, List, Modal, Row } from 'rsuite';
import styled from 'styled-components';

export const RedSubtitle = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bold;
`;

export const BolderParagraph = styled.p`
  font-weight: bolder;
`;

export const ImgSizes = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  margin: 8px 2px 0px;
`;

export const ButtonNotification = styled(Button)`
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  padding: 12px 20;
  margin-bottom: 10px;
  color: #ffffff; // Theme white
  margin-top: 17px;
  margin-bottom: 17px;
`;

export const ButtonRed = styled(Button)`
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 25px;
  padding: 15px;
  width: 100%;
  margin-bottom: 17px;
  &:disabled {
    background: ${props => props.theme.colors.grey};
    color: ${props =>
      props.theme.colors
        .darkGrey}; /* Optional: Use a darker grey for text if needed */
    cursor: not-allowed;
  }
  @media (max-width: 576px) {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonGrey = styled(ButtonRed)`
  background-color: ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.white};
  border-radius: 25px;
  margin-bottom: 17px;
  border: 2px solid ${props => props.theme.colors.white};
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs} {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
`;

export const RowSpace = styled(Row)`
  margin-bottom: 20px;
`;

export const TitleSection = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${props => props.theme.fonts.subtitle};
`;

export const DateP = styled.p`
  font-size: ${props => props.theme.fonts.small};
`;

export const Container = styled.div<{ align: string }>`
  margin: ${props => (props.align === 'right' ? '3% 45% 0 0' : '3% 0 0 45%')};
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.lightBlack};
  padding: 10px 15px;
  background-color: ${props =>
    props.align === 'right'
      ? 'rgba(209, 211, 212, 0.25)'
      : props.theme.colors.red};
  color: ${props =>
    props.align === 'right'
      ? props.theme.colors.lightBlack
      : props.theme.colors.white};
  word-break: break-word;
`;

export const SuggestionsContainer = styled.div`
  margin: 3%;
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.lightBlack};
  padding: 10px 15px;

  word-break: break-word;
`;

export const StyledList = styled(List)`
  cursor: pointer;
  box-shadow: none;
`;

export const MessageTextAreaWrapper = styled.div`
  position: relative;
  width: 100%;

  .EmojiPickerReact {
    position: absolute !important;
    top: -310px;
    right: 10px;
    z-index: 9;
  }
`;

export const MessageIconSmiley = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 13px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
`;

export const ReplySuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  @media ${props => props.theme.breakpoints.xs} {
    margin-bottom: 15px;
  }
`;

export const ReplySuggestionButton = styled(Button)`
  font-family: 'Inter', sans-serif;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  color: #fff;
  border: 1px solid #dd2256;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  width: 35%;

  @media ${props => props.theme.breakpoints.xs} {
    width: 65%;
    font-size: 12px;
  }
  @media (max-width: 768px) {
    width: 55%;
    font-size: 14px;
  }
`;
export const ResponsiveModal = styled.div`
  width: 100%; /* Default size */
  max-width: 600px; /* Constrain modal width */
  height: auto;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 768px) {
    max-width: 100%; /* Adjust width for smaller screens */
  }

  @media (max-width: 480px) {
    max-width: 100%; /* Adjust further for extra small screens */
  }
`;
export const ResponsiveRow = styled(Row)`
  padding: 10px;
  overflow-x: hidden;

  /* Small screens (xs) */
  @media (max-width: 576px) {
    padding: 30px;
  }

  /* Medium screens (md) */
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 15px;
  }
`;
