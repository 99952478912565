/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useState, forwardRef, useImperativeHandle,useCallback } from 'react';
import { Button, Col, FlexboxGrid } from 'rsuite';
import { useTheme } from 'styled-components';
import { TYPES_TOOLS } from '../../../actions/toolsActions';
import { useAuth } from '../../../context/AuthContext';
import axios from '../../../lib/axios';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { notificationError } from '../../../lib/notification';
import { assignAdAccount } from '../../../services/assignAdAccount';
import { assignTool } from '../../../services/assignTool';
import { disableTool } from '../../../services/disableTool';
import { getToolsCompanyObj } from '../../../services/getToolsCompany';
import {
  facebookConnect,
  facebookLogin,
  facebookPageSelected,
} from '../../../services/facebookTools';
import ModalTools from '../../modals/modalTools/ModalTools';
import {
  ButtonAdd,
  ButtonContainer,
  CardPage,
  Container,
} from '../Toogle.styles';

interface FacebookToogleRef {
  handleClick: () => void;
}
interface Props {
  companyId?: number;
  dispatchTool: any;
  stateTool: any;
  invalidateQuery?: () => void;
  signup?: boolean;
  newFormat?: boolean;
  clickedSocial?: string; 
}
const fbd: FacebookData = 
{
  pages:[
    {"access_token":"EAAExZBSMKNVkBO453Iw4z5ePGIh85b8XSYzXATlNFCRxE0JeZACYLs7repJaFSi0ZBXPdLHup7kWZBUzZAvZCRhVH6kygHhi5VxAa8miZAdqxc3yC3rvgRBZAglsWZCoQYqqvby74ZBmZAWhKgKdZCiqRcI8ZCBV9ypcLOcY8wegSw3w34dU0fCXpJKgzyZCkLOHzdCkru","category":"Health & wellness website","category_list":[{"id":"2707","name":"Health & wellness website"},{"id":"2214","name":"Health/beauty"},{"id":"184405378265823","name":"Gym/Physical Fitness Center"}],"name":"K2Organic","id":"444917128708194","tasks":["MODERATE","MESSAGING","ANALYZE","ADVERTISE","CREATE_CONTENT","MANAGE"]},
    {"access_token":"EAAExZBSMKNVkBO1FC3TtOuDqyrGQwphdhomJpeVvKWN3AVUklXodUFSquseGfdoxvzpbX1ZAxhCKubbaONUe1OTxkHwBPveHmeaU4ZBeOjU4Y6DugtYHJs7BQwpJafd3AS1mqYaY2cULZCEELMiQ1FfRkvPZCPnktxV0IF2blW7DTjk4Q54rsuBm1D4u8yudi","category":"Software Company","category_list":[{"id":"1065597503495311","name":"Software Company"}],"name":"Dinabite Admin","id":"413882218465801","tasks":["ADVERTISE","ANALYZE","CREATE_CONTENT","MESSAGING","MODERATE","MANAGE"]},{"access_token":"EAAExZBSMKNVkBO9ZAg1SiI3fgaDcVCHXBxkzZCoNABZBfcLofWSJ3nwT5DOjvMAobCQS7HMP71CxssqaAg9NaCHtkwIqKyM0sG1VvZCZBwnMJY6bYhfXhonMLF5lPlq7Q6UwYZCaIxGi7tCuAGsJI8OeA0VSoHXsHdlUAz4lAWpZBCPZBxcUZBM0E0yfQ4O3k1rfUZD","category":"Computer Company","category_list":[{"id":"2255","name":"Computer Company"},{"id":"1065597503495311","name":"Software Company"},{"id":"187393124625179","name":"Web Designer"}],"name":"Inovation Technologies","id":"134258969766831","tasks":["ADVERTISE","ANALYZE","CREATE_CONTENT","MESSAGING","MODERATE","MANAGE"]},
    {"access_token":"EAAExZBSMKNVkBOxwU9Tayo1fd3FvgAOXyZAx7UJgnOjXTWfJyijfg7m6DDIK1XrLZAXp6wN972rY3ykUr9S7LxtjTKLGXusPfcAAZBEPrOR84vnvu0MgmXV3DYM8s67nroe16tC3hfDVUGxASoQHZCNbh9XQeG3UOUjdZCNUN0EvjD2A9NT6NbgN3HOjb0UZABZA","category":"Restaurant","category_list":[{"id":"273819889375819","name":"Restaurant"}],"name":"Stin Jee Club","id":"965281896895718","tasks":["MODERATE","MESSAGING","ANALYZE","ADVERTISE","CREATE_CONTENT","MANAGE"]},{"access_token":"EAAExZBSMKNVkBOyOggjkwqwZBJ5PGGrsNoDu0bQq5IZBZCSuDSVMYHS6YFsZBEtqQVnvar1LCl66ziwi0U9QeWbARz9pXFuWtISVacEbHhfZCcPuMZBU6yrthdjDAUEGDpSjR7qGIyBJ2yL8HIDjI00D0V6thjleyunFL7PGFqsZAve8HJOV97BLlIW6bNFut4UZD","category":"Advertising Agency","category_list":[{"id":"164886566892249","name":"Advertising Agency"}],"name":"4 Dimension Media","id":"101519625522238","tasks":["MODERATE","MESSAGING","ANALYZE","ADVERTISE","CREATE_CONTENT","MANAGE"]},
    {"access_token":"EAAExZBSMKNVkBO7HTXRhrJRWHCaVsD5S9tODGZBZAuG10gXdndk4qxL9DvJ9x77c85oew4I5BHkiWaYMOCIUh2fpFhB7s3dhu9AG9qpWwLkKyWYRCekXz04jVEKDd2X7ZATmQrFZBx1qL2CEazwT9vZCVsd4VKNZBDZAs2qgQ3ZAT0iPwFMQDTPnGmK9D3cDx9q4i","category":"Photographer","category_list":[{"id":"181475575221097","name":"Photographer"}],"name":"Wazir Naeem Ahmed","id":"374654482911971","tasks":["ADVERTISE","ANALYZE","CREATE_CONTENT","MESSAGING","MODERATE","MANAGE"]},{"access_token":"EAAExZBSMKNVkBOzk9hbV1lwfizNv38L2ipi3TZC2atwxwX0FNqG7hlVfXRNY1YMSZAL4vRZAVKsm2RqR20YWRSn4p2YueZCHMLSCsJzqRa4oEVal3xKFfKjubiZCc9TBspIHZBnh5Plu3Auf6r6wpG5Jh8rhbPXewiFL1inCQlBR6W3EoQxXin8uqDVkHAhhH4ZD","category":"Advertising/Marketing","category_list":[{"id":"1757592557789532","name":"Advertising/Marketing"}],"name":"Dinabite","id":"127281531444649","tasks":["MODERATE","MESSAGING","ANALYZE","ADVERTISE","CREATE_CONTENT","MANAGE"]},
    {"access_token":"EAAExZBSMKNVkBO3cmAmfXKhLAnlzD39tUO1OmedyZAEOAZCeVEgDTZCDeLd2NJmlfOlpZCMLKpEoZAUCHTlL9CCHiO3gBwsiZAlxomGfJsKgjnRzaZC1ZClqv4BYrgfOE81WTgExTwZAGgx8w7tvg2lLYVbRsuJnbY6JsBCQZBXH8iLqbI85dJn99m5sLvia3tg242r","category":"Camera/photo","category_list":[{"id":"2208","name":"Camera/photo"}],"name":"The incrediable Production","id":"1857805617779715","tasks":["MODERATE","MESSAGING","ANALYZE","ADVERTISE","CREATE_CONTENT","MANAGE"]},{"access_token":"EAAExZBSMKNVkBO5AGbrFG4Q5KTS8RfGWk2YFZC9GvIDvZAZACOjvzJeflYlZCL2ZACTmjIQmnMFX3T6cZAsH9zlmZAJZBCFrfn9Bt1ztmDTr3JZBP4fmtKw1np0GiiZB9kYMwFBWjZA7lnDjw4ZBRHZARN9mWUVcYZC9FZCBwoJvZCPxvQcl3KyM8RqudMJt1zoTko1F23Kb1","category":"Camera/photo","category_list":[{"id":"2208","name":"Camera/photo"}],"name":"END Level Production","id":"299694813574540","tasks":["ANALYZE"]},{"access_token":"EAAExZBSMKNVkBOxH0Vv7xwiMwZCcLXnP5xGYH9EkSmrBVCxwJSba25N4xvhaij9eOkAVSBfQAKLuI8FrT0eBJYkZBIFBaA9UoiOwcXqFnu2TUxTmK7BMOP2nNjPjHzDmjXrNFY5y4Mlr2GIU508uDcbxM9svoMozGjG86pOPc6QUplAgtQWAhlqBlCgeai3","category":"Artist","category_list":[{"id":"1601","name":"Artist"}],"name":"InCreDiabLe PhotoGraphy","id":"1555451351339017","tasks":["MODERATE","MESSAGING","ANALYZE","ADVERTISE","CREATE_CONTENT","MANAGE"]}
  ] as FacebookPage[],
    "facebookUserId":"2609055605922434","accountData":[]
};
const FacebookToogle = forwardRef<FacebookToogleRef, Props>(
  (
    {
      companyId,
      stateTool,
      dispatchTool,
      invalidateQuery,
      signup = false,
      newFormat = false,
      clickedSocial, 
    }: Props,
    ref,
  ) => {
    const theme = useTheme();
    const [step, setStep] = useState<'page' | 'ads' | 'switch'>('page');
    const [facebookData, setFacebookData] = useState<FacebookData | null>(null);
    const [switchData, setSwitchData] = useState<[] | null>(null);
    const [dataModalArray, setDataModalArray] = useState<string[]>([]);

  
    useImperativeHandle(
      ref,
      () => ({
        handleClick: () => {
          if (clickedSocial === 'FACEBOOK' ) {
            handleLoginFacebook();
          }
        },
      }),
      [clickedSocial]
    );
    

    const { user } = useAuth();

 
    const activated =
      stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK]?.enabled === true;

    const handleLoginFacebook = async () => {
  
      try {
        setFacebookData(null);
        const facebookAcoount = await facebookLogin();

        const { data } = await facebookConnect(
          facebookAcoount as fb.StatusResponse,
        );

        if (data) {
          setFacebookData(data);
        }
        if(!facebookData){
          // setClickInProgress(false)
        }

        // If instagram is connected and user is logged in, check if we still have permissions for connected Instagram page
        if (stateTool[TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM]?.enabled && user) {
          const pages = data.pages?.map((page: { id: string }) => page.id);

          const { data: instagramCheckData } = await axios.post(
            '/tools/instagram/check',
            {
              pages,
            },
          );

          if (instagramCheckData.instagramToolWasDisabled) {
            notificationError({
              title: 'Instagram',
              description:
                'Instagram tool was disabled, because we lost permissions for connected page',
            });
            dispatchTool({
              type: TYPES_TOOLS.DEACTIVATE_INSTAGRAM,
            });
          }
        }
      } catch (err) {
        notificationError({
          title: 'Error',
          description: `There was an error while connecting to Facebook`,
        });
      }
    };

    const handleAddSelectedPagesClick = async (page: FacebookPage) => {
      if (dataModalArray.includes(page.id)) {
        return;
      }
      setDataModalArray(prev => [...prev, page.id]);

      const fbPageArray: FacebookPage[] = [page];

      if (facebookData) {
        await handleSelectPages(fbPageArray, facebookData?.facebookUserId);
      }
    };

    const handleSelectPages = async (
      pages: FacebookPage[],
      facebookUserId: string,
    ) => {
      const fbPages = await facebookPageSelected(pages);

      const transformedPages: Tool[] = fbPages.map(
        (selectedPage: FacebookPage) => ({
          type: TYPES_OF_SOCIAL_NETWORKS.FACEBOOK,
          pageId: selectedPage.id,
          userId: facebookUserId,
          accessToken: selectedPage.access_token,
          enabled: dataModalArray.length === 0,
          pageName: `${selectedPage.name} - ${selectedPage.category}`,
          adAccountId: null,
          profilePic: selectedPage.picture,
        }),
      );

      if (companyId) {
        const { data } = await assignTool({
          companyId,
          tool: transformedPages,
        });

        if (data && data[0] && dataModalArray.length === 0) {
          dispatchTool({
            type: TYPES_TOOLS.ACTIVATE_FACEBOOK,
            payload: data[0],
          });
        }
      } else {
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_FACEBOOK,
          payload:
            transformedPages[0] /** bug check what to add instead of transformedPages  */,
        });
      }
      invalidateQuery?.();
      //  setStep('ads');
    };

    const handleSelectAdAccount = async (adAccountId: string) => {
      if (companyId) {
        await assignAdAccount(companyId, adAccountId);
      }
      dispatchTool({
        type: TYPES_TOOLS.CONNECT_AD_ACCOUNT,
        payload: {
          adAccountId,
        },
      });
      setFacebookData(null);
      setDataModalArray([]);
      setStep('page');
    };

    const handleShowPages = async () => {
      const { data: dataTools } = await getToolsCompanyObj(companyId);
      setSwitchData(dataTools.facebook);
      setStep('switch');
    };
    const handleSelectPage = async (page: any) => {
      const defaultSelectContent = [
        {
          type: TYPES_OF_SOCIAL_NETWORKS.FACEBOOK,
          accessToken: page.accessToken,
          pageId: page.pageId,
          // pageName: `${name} - ${category}`,
          userId: page.userId,
          // adAccountId: null,
          enabled: true,
          id: page.id,
        },
      ];

      if (companyId) {
        const { data } = await assignTool({
          companyId,
          tool: defaultSelectContent,
        });
        dispatchTool({
          type: TYPES_TOOLS.ACTIVATE_FACEBOOK,
          payload: data[0],
        });
        setSwitchData(null);
      }
    };

    // const wid = newFormat ? '69%' : '100%';
    // const gp = newFormat ? '8px' : '0px';

    console.log('object | facebookData', facebookData)

    return (
      <>
        {facebookData &&
          step === 'page' &&
          (!facebookData?.pages || facebookData?.pages?.length === 0) && (
            <ModalTools
              setDataModal={setFacebookData}
              dataModal={facebookData}
              title="Connect Facebook"
              description="No connected pages as admin">
              <CardPage align="middle">
                <FlexboxGrid.Item>
                  <span>
                    No connected pages. Talk to your Facebook admin to get
                    connected.
                  </span>
                </FlexboxGrid.Item>
              </CardPage>
            </ModalTools>
          )}
        {facebookData && step === 'page' && facebookData?.pages && (
          <ModalTools
            setDataModal={setFacebookData}
            dataModal={facebookData}
            title="Connect Facebook"
            description="Select the Facebook page"
            setDataModalArray={setDataModalArray}
            setStep={setStep}
            step="ads">
            {facebookData?.pages && (
              <div>
                {facebookData.pages.map((page: FacebookPage) => {
                  const isDisabled = dataModalArray.includes(page.id);
                  return (
                    <CardPage align="middle" key={page.id}>
                      <FlexboxGrid.Item xs={20} componentClass={Col}>
                        <span>
                          {page.name} - {page.category}
                        </span>
                      </FlexboxGrid.Item>
                      <FlexboxGrid.Item xs={4} componentClass={Col}>
                      <Button
                        disabled={isDisabled}
                        onClick={(event: React.SyntheticEvent) => {
                          event.stopPropagation(); 
                          handleAddSelectedPagesClick(page);
                        }}
                      >
                        Add Page
                      </Button>

                      </FlexboxGrid.Item>
                    </CardPage>
                  );
                })}
              </div>
            )}
          </ModalTools>
        )}

        {facebookData && facebookData.accountData.length > 0 && step === 'ads' && (
          <ModalTools
            setDataModal={setFacebookData}
            dataModal={facebookData}
            title="Connect Facebook"
            description="Select ad account">
            {facebookData?.accountData && (
              <div>
                {facebookData.accountData.map(
                  (account: FundingSourceDetails) => {
                    return (
                      <CardPage align="middle" key={account.id}>
                        <FlexboxGrid.Item xs={20} componentClass={Col}>
                          <span>
                            {account.business_name} - {account.id.split('_')[1]}
                          </span>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item xs={4} componentClass={Col}>
                          <Button
                            onClick={() => handleSelectAdAccount(account.id)}>
                            Add
                          </Button>
                        </FlexboxGrid.Item>
                      </CardPage>
                    );
                  },
                )}
              </div>
            )}
          </ModalTools>
        )}

        {switchData && step === 'switch' && (
          <ModalTools
            setDataModal={setSwitchData}
            dataModal={switchData}
            title="Switch Facebook pages"
            description="Select the Facebook page to switch to"
            setStep={setStep}
            step="page">
            <div>
              {switchData?.map((page: any) => {
                return (
                  <CardPage align="middle" key={page.pageId}>
                    <FlexboxGrid.Item componentClass={Col} xs={20}>
                      <span>{page.pageName}</span>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item xs={4} componentClass={Col}>
                      <Button
                        disabled={page.enabled}
                        onClick={() => handleSelectPage(page)}>
                        {page.enabled ? 'Activated' : 'Activate'}
                      </Button>
                    </FlexboxGrid.Item>
                  </CardPage>
                );
              })}
            </div>
          </ModalTools>
        )}

        {/* <> */}
        {/* {activated && (
          <Container
            style={{
              display: 'flex',
              flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
              justifyContent: signup ? 'center' : 'space-between',
              alignItems: 'center',
              padding: signup ? '20px' : '10px',
              borderRadius: signup ? '10px' : '0',
              width: signup ? '20%' : '100%', // Set width to 40% when signup is true
              margin: '0 auto', // Center the container
              gap: signup ? '10px' : '0', // Add gap between buttons in column layout
            }}>
            <ButtonAdd
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}>
              Switch
            </ButtonAdd>
            <ButtonAdd
              // bgColor={theme.colors.red}
              bgColor={signup ? 'black' : theme.colors.crimson}
              onClick={() => {
                if (companyId) {
                  disableTool(
                    stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id,
                  ).then(() => {
                    dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                    invalidateQuery?.();
                  });
                } else {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                }
              }}
              style={{
                width: signup ? '120px' : 'auto',
              }}>
              Disconnect
            </ButtonAdd>
          </Container>
        )}

        {!activated && (
          <ButtonContainer signup={signup}>
            <ButtonAdd
              bgColor={theme.colors.red}
              onClick={handleLoginFacebook}
              signup={signup}>
              Connect
            </ButtonAdd>
          </ButtonContainer>
        )}
      </> */}

        {/* <Toggle
        checked={activated}
        onChange={(check: boolean) =>
          check
            ? handleLoginFacebook()
            : companyId
              ? disableTool(stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id).then(
                () => {
                  dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                  invalidateQuery?.();
                },
              )
              : dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK })
        }
      /> */}
        {signup ? (
          // When signup is true, show Connect and Disconnect
          <>
            {activated && (
              <Container
                style={{
                  // display: 'flex',
                  // flexDirection: signup ? 'column' : 'row',
                  justifyContent: signup ? 'end' : 'space-between',
                  // alignItems: 'center',
                  // padding: signup ? '20px' : '10px',
                  // borderRadius: signup ? '10px' : '0',
                  // width: signup ? '20%' : '100%',
                  // margin: '0 auto',
                  // gap: signup ? '10px' : '0',
                }}>
                {/* <ButtonAdd
              bgColor={signup ? 'black' : theme.colors.crimson}
              color={signup ? 'black' : theme.colors.crimson}
              width={signup ? '30px' : 'auto'}
              onClick={handleShowPages}
              style={{
                width: signup ? '120px' : 'auto',
                marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
              }}>
              Switch
            </ButtonAdd> */}
                {!newFormat && (
                  <ButtonAdd
                    bgColor={signup ? '#fff' : theme.colors.grey} // Set background color based on signup
                    // bgColor={signup ? '#fff' : theme.colors.crimson}
                    onClick={() => {
                      if (companyId) {
                        disableTool(
                          stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id,
                        ).then(() => {
                          dispatchTool({
                            type: TYPES_TOOLS.DEACTIVATE_FACEBOOK,
                          });
                          invalidateQuery?.();
                        });
                      } else {
                        dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                      }
                    }}
                    disabled={signup} // Disable the button when signup is true
                    pad={!activated ? '8px 12px' : '8px 4px'}
                    style={{
                      width: signup ? '120px' : 'auto',
                      color: signup ? '#64E000' : 'inherit',
                      fontFamily: signup ? "'Inter', sans-serif" : 'inherit', // Set the font family
                      cursor: signup ? 'not-allowed' : 'pointer', // Change cursor style when disabled
                      fontSize: signup ? '14px' : 'inherit', // Set font size based on signup condition
                      padding: signup
                        ? '2px 15px'
                        : !activated
                        ? '8px 12px'
                        : '8px 4px', // Conditional padding
                    }}>
                    {/* Disconnect */}
                    Connected
                  </ButtonAdd>
                )}
              </Container>
            )}

            {!activated && (
              <ButtonContainer signup={signup}>
                <ButtonAdd
                  bgColor={signup ? theme.colors.red : theme.colors.grey}
                  // onClick={handleLoginFacebook}
                  onClick={handleLoginFacebook}
                  pad={!activated ? '8px 12px' : '8px 4px'}
                  signup={signup}>
                  Connect
                </ButtonAdd>
              </ButtonContainer>
            )}
          </>
        ) : (
          // When signup is false, show Switch and Disconnect
          <>
            {activated && (
              <Container
                style={{
                  display: 'flex',
                  flexDirection: signup ? 'column' : 'row', // Stack buttons vertically if signup is true
                  justifyContent: signup ? 'center' : 'space-between',
                  alignItems: 'center',
                  padding: signup ? '20px' : 'inherit',
                  borderRadius: signup ? '10px' : 'inherit',
                  width: signup ? '20%' : '', // Set width to 40% when signup is true
                  gap: signup ? '10px' : '5px', // Add gap between buttons in column layout
                }}>
                <ButtonAdd
                  bgColor={signup ? 'black' : theme.colors.crimson}
                  color={signup ? 'black' : theme.colors.crimson}
                  pad={!activated ? '8px 12px' : ''}
                  width={signup ? '30px' : 'auto'}
                  onClick={handleShowPages}
                  style={{
                    width: signup ? '120px' : 'auto',
                    marginBottom: signup ? '5px' : '0', // Add margin between buttons in column layout
                  }}>
                  Switch
                </ButtonAdd>
                <ButtonAdd
                  // bgColor={theme.colors.red}
                  bgColor={signup ? 'black' : theme.colors.crimson}
                  pad={!activated ? '8px 12px' : ''}
                  onClick={() => {
                    if (companyId) {
                      disableTool(
                        stateTool[TYPES_OF_SOCIAL_NETWORKS.FACEBOOK].id,
                      ).then(() => {
                        dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                        invalidateQuery?.();
                      });
                      // setClickInProgress(true); 
                    } else {
                      dispatchTool({ type: TYPES_TOOLS.DEACTIVATE_FACEBOOK });
                      // setClickInProgress(true); 
                    }
                  }}
                  style={{
                    width: signup ? '120px' : '',
                  }}>
                  Disconnect
                </ButtonAdd>
              </Container>
            )}

            {!activated && (
              <ButtonContainer signup={signup}>
                <ButtonAdd
                  bgColor={theme.colors.grey}
                  pad={!activated ? '8px 12px' : '8px 4px'}
                  onClick={handleLoginFacebook} // Your connect handler
                >
                  Connect
                </ButtonAdd>
              </ButtonContainer>
            )}
          </>
        )}
      </>
    );
  },
);

export default FacebookToogle;
