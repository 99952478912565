import React, { useState } from "react";
import {
  StepHeaderContainer,
  CreateAccountTitle,
  Stepbodycontainer,
} from "./dinabitesign.styles";
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
  ImageContainermobile

 } from './globaldinabite.style'

import Step0 from "./step0";
import signupImage from "../../assets/images/signup.png";
import {  getTextForStep } from "../steputils/steputils";
// import { getTitleForStep, getTextForStep } from "../steputils/steputils";

const DinabiteSignupPanel = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  return (
    <Container>
      <LeftPane>
        <StepHeaderContainer>
          {/* <CreateAccountTitle>{getTitleForStep(currentStep)}</CreateAccountTitle> */}
          <CreateAccountTitle>Login in or Sign up</CreateAccountTitle>
        </StepHeaderContainer>
        <ImageContainermobile>
          {currentStep === 0 && <StyledImage src={signupImage} alt="Illustration" />}
        </ImageContainermobile>
        <Stepbodycontainer>
          {currentStep === 0 && <Step0 onNext={handleNextStep} />}
        </Stepbodycontainer>
      </LeftPane>
      <RightPane>
        <ImageContainer>
          {currentStep === 0 && <StyledImage src={signupImage} alt="Illustration" />}
          <DinabiteText>{getTextForStep(currentStep)}</DinabiteText>
        </ImageContainer>
      </RightPane>
    </Container>
  );
};

export default DinabiteSignupPanel;
