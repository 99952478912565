/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from '../lib/axios';
import { capitalize } from '../lib/helpers';
import { notification, notificationError } from '../lib/notification';
import { postVideoToTikTok } from './postToTikTok';

interface Props {
  title: string;
  mediaId: number | number[];
  socialNetworks: string[];
  scheduledAt: any;
  isPrivacyLevel: string;
  allowComments: boolean;
  allowDuet: boolean;
  allowStitch: boolean;
  brandOrganicToggle: boolean;
  brandContentToggle: boolean;
  disclosePostContent: boolean;
  isAiGenerated: boolean;
}

export const reelsSocial = async ({
  title,
  mediaId,
  socialNetworks,
  scheduledAt,
  isPrivacyLevel,
  allowComments,
  allowDuet,
  allowStitch,
  brandOrganicToggle,
  brandContentToggle,
  disclosePostContent,
  isAiGenerated,
}: Props) => {
  try {
    console.log('object | mediaId', mediaId);

    let tiktokData: any = {};
    let otherNetworksData: any = {};

    const promises = [];

    // Handle TikTok post
    if (socialNetworks.includes('tiktok')) {
      promises.push(
        postVideoToTikTok({
          title,
          mediaId,
          socialNetworks: ['tiktok'],
          isPrivacyLevel: isPrivacyLevel!,
          allowComments: allowComments!,
          allowDuet: allowDuet!,
          allowStitch: allowStitch!,
          brandOrganicToggle: brandOrganicToggle!,
          brandContentToggle: brandContentToggle!,
          disclosePostContent: disclosePostContent!,
          isAiGenerated: isAiGenerated!,
        }).then(data => {
          tiktokData = data;
        }),
      );
    }

    // Handle Facebook/Instagram post
    const otherNetworks = socialNetworks.filter(
      network => network !== 'tiktok',
    );

    if (otherNetworks.length > 0) {
      promises.push(
        axios
          .post('/reels', {
            title,
            mediaId,
            socialNetworks: otherNetworks,
            scheduledAt,
          })
          .then(response => {
            const { data } = response;
            otherNetworksData = data;
          }),
      );
    }

    await Promise.all(promises);

    const finalData = { ...tiktokData, ...otherNetworksData };

    if (finalData) {
      Object.entries(finalData).forEach(
        ([socialName, content]: [string, any]) => {
          // console.log('socialName', socialName);
          // console.log('content', content);
          if (content === true) {
            notification({
              type: 'success',
              title: capitalize(socialName),
              description: 'Post was successfully published',
            });
          } else {
            notificationError({
              title: capitalize(socialName),
              description: content || 'The post could not be made.',
            });
          }
        },
      );
    }

    return finalData;
  } catch (error: any) {
    if (error.response && error.response.status === 403) {
      notificationError({
        title: 'Error',
        description: 'API Key not correct. Please provide a valid API key.',
        duration: 8000,
      });
      return error;
    }
    // console.log('error', error);
    if (error) {
      notification({
        type: 'success',
        title: 'Reel Status',
        description:
          'Reel was successfully published and it will be visible on this page shortly. Please refresh your page to see it.',
      });
    }
    // notificationError({
    //   title: 'Error',
    //   description: 'Something went wrong',
    // });
    return error;
  }
};
