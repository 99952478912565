import styled from 'styled-components';
import { Card } from '../Cards.styles';

export const Container = styled(Card)<{ withoutMargin: boolean }>`
  margin: ${props => (props.withoutMargin ? `0 20px 0 0` : `20px 20px 20px 0`)};
  display: flex;
  justify-content: center;

  height: 150px;

  .rs-panel-body {
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.maxMedium} {
    margin: 10px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ContentContainer = styled.div`
  font-family: 'Poppins';
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  h1 {
    font-size: ${props => props.theme.fonts.paragraph};
    color: ${props => props.theme.colors.darkGrey};
    line-height: 1;
    text-transform: capitalize;
  }

  h2 {
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.fonts.title};
    font-weight: bold;
    text-align: center;
    margin: 10px 0;

    @media screen and (max-width: 768px) {
      font-size: ${props => props.theme.fonts.subtitle};
    }
  }
`;

export const DifferenceText = styled.div<{
  type: 'neutral' | 'positive' | 'negative';
}>`
  text-align: center;
  color: ${props => {
    if (props.type === 'positive') return props.theme.colors.softGreen;

    if (props.type === 'negative') return props.theme.colors.red;

    return props.theme.colors.grey;
  }};
  font-size: ${props => props.theme.fonts.paragraph};
`;
export const DifferenceText2 = styled.div<{
}>`
  text-align: center;
  color:rgb(201, 197, 197);
  font-size: 14px;
`;
