/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactNode } from 'react';
import { Col, FlexboxGrid, Row } from 'rsuite';
import { selectImgStatus, showPageName } from '../../../lib/toogleImgStatus';
import {
  CardTool,
  //  Paragraph,
  ParagraphColor,
  ParagraphColor2,
  SocialNetworkIcon,
  TooltipWrapper,
  TooltipText,
  CardToolsWrapper,
  IconWrapper,
  ParagraphColor4,
  SmallSocialNetworkIcon,
} from './toolToogleCard.styles';

interface Props {
  newFormat?: boolean;
  signup?: boolean;
  children: ReactNode;
  type: string;
  content: any;
  noText?: boolean;
  onClick: () => void; 

}

const ToolToogleCard: React.FunctionComponent<Props> = ({
  newFormat,
  signup = false, 
  children,
  type,
  content,
  noText = false,
  onClick, 

  
}: Props) => {
  const flexboxGridStyle = {
    // display: 'flex',
    justifyContent: signup ? 'center' : 'space-between',
  };

  const displayName = content?.pageName
    ? content.pageName
    : type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <>
      {newFormat ? (
        <CardToolsWrapper onClick={onClick}>
          <IconWrapper>
            <div className="wrapper">
              {content?.profilePic && (
                <img className="img-parent" src={content?.profilePic} alt="" />
              )}
              <SmallSocialNetworkIcon
                show={content?.profilePic}
                src={selectImgStatus(type, content?.enabled).image}
                alt={`${type} Social Network Icon`}
                signup={signup}
              />
            </div>
            <Col xs={24}>
              <span hidden={!noText}>
                {signup ? null : (
                  <ParagraphColor4 status={content?.enabled ? 1 : 0}>
                    {displayName}
                  </ParagraphColor4>
                )}
              </span>
            </Col>
          </IconWrapper>

          <>{children}</>
        </CardToolsWrapper>
      ) : (
        <CardTool align="middle" signup={signup}   
        onClick={(event: React.MouseEvent<Element>) => {
          event.stopPropagation();
          if (!content?.enabled && !selectImgStatus(type, content?.enabled).disabled) {
            onClick(); 
          }
        }}
        
        style={{
          cursor: !content?.enabled ? 'pointer' : 'default', 
        }}>
          <FlexboxGrid.Item componentClass={Col} xs={5} lg={2}>
            <SocialNetworkIcon
              src={selectImgStatus(type, content?.enabled).image}
              alt="Social Network Icon"
              signup={signup}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} xs={8} lg={14}>
            <FlexboxGrid style={flexboxGridStyle}>
              <Row>
                <Col xs={24}>
                  <span hidden={!noText}>
                    {signup ? null : content?.enabled ? (
                      <ParagraphColor2 status={1}>
                        {content.pageName}
                      </ParagraphColor2>
                    ) : selectImgStatus(type, content?.enabled).disabled ? (
                      <ParagraphColor>Coming Soon</ParagraphColor>
                    ) : (
                      <ParagraphColor status={0} signup={signup}>
                        Disconnected
                      </ParagraphColor>
                    )}
                  </span>
                </Col>
              </Row>
            </FlexboxGrid>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item componentClass={Col} xs={10} lg={8} >
            {children}
          </FlexboxGrid.Item>
        </CardTool>
      )}
    </>
  );
};

export default ToolToogleCard;
