import { useState } from 'react';
import { useQuery } from 'react-query';
import { Col, FlexboxGrid, Loader, Row } from 'rsuite';
import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import TwitterIcon from '../../assets/icons/x.svg';
import StatisticCard from '../../components/cards/statisticCard/StatisticCard';
import SelectButtons from '../../components/selectButtons/SelectButtons';
import axios from '../../lib/axios';
import { Container, TitleContent } from '../../styles/generalStyles';

const CONTENT_SELECT_BUTTONS = [
  {
    value: 'yesterday',
    content: 'Yesterday',
  },
  {
    value: 'last7Days',
    content: 'Last 7 Days',
  },
];

const Analytics = () => {
  const [filter, setFilter] = useState(CONTENT_SELECT_BUTTONS[0].value);

  const fetchStatistics = async (): Promise<Statistics> => {
    try {
      const { data } = await axios.get(`/tools/statistics?filter=${filter}`);
      console.log('checking data ', data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  };

  const {
    isLoading,
    isError,
    isFetching,
    data: statistics,
  } = useQuery(`STATISTICS-${filter}`, fetchStatistics, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  return (
    <Container>
      <Col xs={24} md={24} style={{ height: '50px' }}>
        <FlexboxGrid justify="end">
          <SelectButtons
            content={CONTENT_SELECT_BUTTONS}
            defaultValue={filter}
            setPage={setFilter}
          />
        </FlexboxGrid>
      </Col>

      <Row
        style={{
          height: 'calc(100% - 50px)',
          marginLeft: '0',
          marginRight: '0',
        }}>
        <Col xs={24} md={24}>
          <TitleContent>Engagement</TitleContent>
          {(isLoading || isFetching) && !isError && (
            <Loader center content="loading" />
          )}
        </Col>

        {!isLoading && !isFetching && !isError && statistics && (
          <>
            {/* {statistics.facebook && (
              <Row>
                <StatisticCard
                  socialImage={FacebookIcon}
                  label="Impressions"
                  statistics={statistics.facebook.impressions}
                />
                <StatisticCard
                  socialImage={FacebookIcon}
                  label="Views"
                  statistics={statistics.facebook.views}
                />
                <StatisticCard
                  socialImage={FacebookIcon}
                  label="Website visits"
                  statistics={statistics.facebook.website}
                />
                <StatisticCard
                  socialImage={FacebookIcon}
                  label="New likes"
                  statistics={statistics.facebook.likes}
                />
              </Row>
            )} */}
            {statistics.facebook && statistics.facebook.message ? (
              <Row>
                <StatisticCard
                  socialImage={FacebookIcon}
                  label="Facebook Data: N/A "
                  statistics="helloooo "
                />
              </Row>
            ) : (
              statistics.facebook && (
                <Row>
                  <StatisticCard
                    socialImage={FacebookIcon}
                    label="Impressions"
                    statistics={statistics.facebook.impressions}
                  />
                  <StatisticCard
                    socialImage={FacebookIcon}
                    label="Views"
                    statistics={statistics.facebook.views}
                  />
                  <StatisticCard
                    socialImage={FacebookIcon}
                    label="Website visits"
                    statistics={statistics.facebook.website}
                  />
                  <StatisticCard
                    socialImage={FacebookIcon}
                    label="New likes"
                    statistics={statistics.facebook.likes}
                  />
                </Row>
              )
            )}

            {statistics.instagram && (
              <Row>
                <StatisticCard
                  socialImage={InstagramIcon}
                  label="Impressions"
                  statistics={statistics.instagram.impressions}
                />
                <StatisticCard
                  socialImage={InstagramIcon}
                  label="Views"
                  statistics={statistics.instagram.views}
                />
                <StatisticCard
                  socialImage={InstagramIcon}
                  label="Website visits"
                  statistics={statistics.instagram.website}
                />
                <StatisticCard
                  socialImage={InstagramIcon}
                  label="New followers"
                  statistics={statistics.instagram.followers}
                />
              </Row>
            )}
            {statistics.google && (
              <Row>
                <StatisticCard
                  socialImage={GoogleIcon}
                  label="Searches"
                  statistics={statistics.google.searches}
                />
                <StatisticCard
                  socialImage={GoogleIcon}
                  label="Views"
                  statistics={statistics.google.views}
                />
                <StatisticCard
                  socialImage={GoogleIcon}
                  label="Website visits"
                  statistics={statistics.google.website}
                />
              </Row>
            )}
            {statistics.twitter && (
              <Row>
                <StatisticCard
                  socialImage={TwitterIcon}
                  label="Likes"
                  statistics={statistics.twitter.likes}
                />
                <StatisticCard
                  socialImage={TwitterIcon}
                  label="Shares"
                  statistics={statistics.twitter.shares}
                />
              </Row>
            )}
          </>
        )}
      </Row>
    </Container>
  );
};

export default Analytics;
