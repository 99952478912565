import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { FlexboxGrid as RSFlexboxGrid } from 'rsuite';

export const ContentContainer = styled.div`
  // flex: 1;
  width: 100%;
  height: 95%;
  margin: 0 auto;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 780px) {
    width: 100%;
    // overflow-y: auto;
    margin-bottom: 100px;
  }
`;
export const Title = styled.h2`
  height: 10%;
  margin-top: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 35px;
  color: #dd2256;
  margin: 0 auto 30px auto;
  padding: 0 60px;
  font-family: 'Inter', sans-serif;
  text-align: center; /* Center the text */

  @media (max-width: 1480px) {
    font-size: 30px;
    margin: 0 auto 15px auto;
    padding: 0 60px;
  }
  @media (max-width: 1280px) {
    font-size: 22px;
    margin: 0 auto 15px auto;
    padding: 0 60px;
  }
  @media (max-width: 1024px) {
    font-size: 22px;
    margin: 0 auto 10px auto;
    padding: 0 60px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Subtitle = styled.p`
  height: 10%;
  font-family: 'Inter', sans-serif;
  width: 85%;
  font-size: 18px;
  color: #666;
  margin-bottom: 60px;
  padding: 0 50px;
  text-align: center; /* Center the text */
  @media (max-width: 1480px) {
    font-size: 14px;
    color: #666;
    margin-bottom: 60px;
    padding: 0 50px;
  }
  @media (max-width: 1280px) {
    font-size: 14px;
    padding: 0 50px;
    margin-bottom: 50px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 0 50px;
    margin-bottom: 50px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Titlembile = styled.h2`
  display: none; /* Hide by default */

  @media (max-width: 780px) {
  display:flex:
  width:100%;
  justify-content:center:
  flex-direction:row;
    font-family: 'Inter', sans-serif;
    display: block; /* Show on mobile */
    font-size: 26px;
    color: #dd2256;
    font-family: 'Inter', sans-serif;
    text-align: left; /* Align text to the left */
    @media (max-width: 480px) {
      width: 65%;
      font-size: 22px;
        margin:0 auto;

    }
    @media (max-width: 380px) {
      width: 80%;
      font-size: 22px;
        margin:0 auto;

    }
  }
`;
export const Subtitlemobile = styled.p`
  display: none;
  @media (max-width: 780px) {
  
    width: 65%;
    font-family: 'Inter', sans-serif;
    display: block;
    font-size: 14px;
    color: #666;
    text-align: left; /* Center the text */
    margin-bottom: 5px;
  }

  @media (max-width: 480px) {
    width: 85%;
    padding: 0 20px;
    font-size: 12px;
  }
  @media (max-width: 380px) {
    width: 85%;
    padding: 0 20px;
    font-size: 12px;
  }
`;

export const socialcontainers = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  background: #fff;
  justify-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  min-height: 150px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Add a shadow to distinguish it from the content above */
  margin: 0 auto;
  @media (max-width: 780px) {
    display: none;
  }
`;
export const SocialButton = styled.button`
  display: flex;
  margin: 0 auto;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically in the center */
  font-family: 'Inter', sans-serif;
  width: 70%;
  background-color: white;
  color: #dd2256;
  border: 1px solid #dd2256;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  gap: 18px; /* Space between the icon and text */
  margin-bottom: 10px;
  &:hover {
    background-color: #dd2256;
    color: white;
  }

  @media (max-width: 1480px) {
    width: 60%;
    padding: 7px;
    border-radius: 12px;
  }
  @media (max-width: 1280px) {
    width: 65%;
    padding: 10px;
  }
  @media (max-width: 780px) {
    width: 65%;
    margin: 10px auto;
    padding: 10px;
  }
  @media (max-width: 480px) {
    width: 80%;
    padding: 10px;
  }
  @media (max-width: 380px) {
    padding: 10px;
  }
`;

export const SocialText = styled.span`
  font-family: 'Inter', sans-serif;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; /* Center text vertically */

  @media (max-width: 1480px) {
    font-size: 0.8rem;
  }
  @media (max-width: 1020px) {
    font-size: 0.8rem;
  }
  @media (max-width: 780px) {
    font-size: 18px;
    font-size: 0.8rem;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export const BackButtondiv = styled.div`
  display: none;  // Hidden by default
  width: 100%;

  @media (max-width: 780px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff  ; 
    height:80px;
  }   
`;

export const BackButtonnext = styled.button`
  color: #fff;
  margin-top:5px;
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;  // Added padding for consistency
  height: 2.5rem;

  @media (max-width: 780px) {
    width: 20%;

  }
  @media (max-width: 480px) {
    font-size: 18px;
    border-radius: 15px;
    width: 30%;

  }

  @media (max-width: 380px) {
    font-size: 18px;
    border-radius: 15px;
    margin-bottom: 30px;
      width: 30%;

  }
`;



export const NextButtonContainer = styled.div`
  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  margin-bottom: 20px;
  @media (max-width: 780px) {
    display: none;
  }
`;

export const Button = styled.button`
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  font-family: 'Inter', sans-serif;
  color: #fff;
  padding: 8px 30px;
  margin: 0 auto;
  border: 2px solid #dd2256;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 1440px) {
    font-size: 16px;
    padding: 5px 20px;
  }

  @media (max-width: 1280px) {
    font-size: 16px;
    padding: 5px 20px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 5px 20px;
  }

  @media (max-width: 780px) {
    font-size: 14px;
    padding: 12px 25px;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 12px 20px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    padding: 10px 20px;
  }
`;
export const FlexboxGrid = styled(RSFlexboxGrid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: space-between; /* Align items to the top */
  width: 100%; /* Ensure it takes full width of its container */
  border-radius: 8px; /* Optional: Rounded corners */
  @media (max-width: 780px) {
    width: 100%; /* Ensure it takes full width of its container */
  }
  &:hover {
    color: black;
  }
`;

// Define custom styles for FlexboxGrid items
export const FlexboxGridItem = styled(RSFlexboxGrid.Item)`
  // margin: 8px 0;
  padding: 6px;
  background-color: #ffffff; /* Background color for items */
  border-radius: 4px; /* Rounded corners for items */
  width: 65%;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1480px) {
    width: 60%;
  }
`;
export const LinkForm = styled(Link)`
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  font-size: 12px;
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 200;
  font-family: 'Inter', sans-serif;
  border-bottom: 0.2px solid #b2b2bd; /* Light gray line */
  :hover {
    color: ${props => props.theme.colors.red};
  }
`;
export const LinkForm2 = styled(Link)`
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 16px;
  color: #757373;
  cursor: pointer;
  text-decoration: none !important;
  font-family: 'Inter', sans-serif;
  :hover {
    color: ${props => props.theme.colors.red};
  }
  margin-top: 30px;
  @media (max-width: 1480px) {
    font-size: 14px;
  }
  @media (max-width: 1280px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 780px) {
    font-size: 16px;
  }
`;
