import { Col } from 'rsuite';
import { formatNumber, percentageText } from '../../../lib/helpers';
import {
  Container,
  ContentContainer,
  DifferenceText,
  HeaderContainer,
  Wrapper,
  DifferenceText2,
} from './StatisticCard.styles';

interface Props {
  label: string;
  socialImage?: string;
  statistics?: SocialNetworkStatistic|string;
  simple?: boolean;
  value?: number | string;
  decimalNumbers?: number;
  withoutMargin?: boolean;
  dashboardCard?: boolean;
}

const StatisticCard = ({
  socialImage,
  statistics,
  label,
  simple,
  value,
  decimalNumbers,
  withoutMargin,
  dashboardCard,
}: Props) => {
  if (simple) {
    return (
      <Col
        xs={dashboardCard ? 12 : 24}
        sm={12}
        md={12}
        lg={dashboardCard ? 8 : 6}>
        <Container withoutMargin={withoutMargin}>
          <Wrapper>
            {socialImage && (
              <HeaderContainer>
                <img src={socialImage} alt="socialNetwork" loading="lazy" />
              </HeaderContainer>
            )}
            <ContentContainer>
              <h1>{label}</h1>
              {typeof value === 'number' ? (
                <h2>{formatNumber(value || 0, decimalNumbers)}</h2>
              ) : (
                <h2>{value}</h2>
              )}
            </ContentContainer>
          </Wrapper>
        </Container>
      </Col>
    );
  }

 
  if (statistics && typeof statistics !== 'string') {
    const { currentNumber, percentageDifference, percentageType, oldNumber } =
      statistics;

    return (
      <Col
        xs={dashboardCard ? 12 : 24}
        sm={12}
        md={12}
        lg={dashboardCard ? 8 : 6}>
        <Container withoutMargin={withoutMargin}>
          <Wrapper>
            {socialImage && (
              <HeaderContainer>
                <img src={socialImage} alt="socialNetwork" loading="lazy" />
              </HeaderContainer>
            )}
            <ContentContainer>
              <h1>{label}</h1>
              <h2>{formatNumber(currentNumber)}</h2>
              <DifferenceText type={percentageType}>
                {percentageText(
                  oldNumber,
                  percentageDifference,
                  percentageType,
                )}
              </DifferenceText>
            </ContentContainer>
          </Wrapper>
        </Container>
      </Col>
    );
  }

  if (typeof statistics === 'string' || statistics) {
    const data = [
      { label: 'Impressions', message: 'N/A', details: 'The page has less than 100 likes' },
      { label: 'Views', message: 'N/A', details: 'The page has less than 100 likes' },
      { label: 'Website Visits', message: 'N/A', details: 'The page has less than 100 likes' },
      { label: 'New Likes', message: 'N/A', details: 'The page has less than 100 likes' },
    ];
    return (
      <>
      {data.map((item) => (
        <Col
          xs={dashboardCard ? 12 : 24}
          sm={12}
          md={12}
          lg={dashboardCard ? 8 : 6}
          key={item.label}>
          <Container withoutMargin={withoutMargin}>
            <Wrapper>
              {socialImage && (
                <HeaderContainer>
                  <img src={socialImage} alt="socialNetwork" loading="lazy" />
                </HeaderContainer>
              )}
              <ContentContainer>
                <h1>{item.label}</h1>
                <h2>{item.message}</h2>
                <DifferenceText2>{item.details}</DifferenceText2>
              </ContentContainer>
            </Wrapper>
          </Container>
        </Col>
      ))}
    </>

    );
  }
  return null;
};

export default StatisticCard;
