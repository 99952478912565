import { DefaultTheme } from 'styled-components';

const size = {
  xxs: '414px',
  xs: '480px',
  md: '768px',
  sm: '1170px',
  lg: '1200px',
  xl: '1400px',
};

const theme: DefaultTheme = {
  colors: {
    black: '#000000',
    lightBlack: '#707070',
    white: '#ffffff',
    plastic: '#F2989A1A',
    darkGrey: '#939598',
    darkHardGrey: '#65676B',
    softGrey: '#D1D3D440',
    greyLetter: '#939598',
    grey: '#d1d3d4',
    lightGrey: '#F0F2F5',
    red: '#dd2256',
    roseRush: '#DD2256',
    pinkRed: '#f2989a1a',
    crimson: '#DC2058',
    green: '#59c36f',
    softGreen: '#00C864',
    orange: '#FF7E00',
    blue: '#6CDAFA',
    gradient: '#EA4435',
    facebook: '#3d5998',
    twitter: '#01abee',
    instagram:
      'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%)',
    facebookTextareColor: 'rgba(24, 119, 242, 0.2)',
    newThemeColor: '#dd2256',
    gradientred: '#E12F48',
  },

  fonts: {
    title: '2em', // 32px
    bigSubtitle: '1.5em', // 24px
    subtitle: '1.25em', // 20px
    paragraph: '1em', // 16px
    small: '0.77em', // 12px
  },

  breakpoints: {
    xss: `(max-width: ${size.xxs})`,
    xs: `(max-width: ${size.xs})`,
    xml: `(max-width: ${size.xl})`,
    sm: `(min-width: ${size.xs}) and (max-width: ${size.sm})`,
    mm: `(max-width: ${size.md})`,
    maxMedium: `(max-width: 700px)`,
    md: `(min-width: ${size.sm}) and (max-width: ${size.lg})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
  },
};

export default theme;
