/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState ,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import axios from '../../lib/axios';

import {
  StepHeaderContainer,
  CreateAccountTitle,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  Button,
  ContentContainer,
  BackButtondiv,
  DesktopBackButton,
  BackButton,
  BackButtonnext,
  StyledErrorMessage,
} from './dinabitesignin.styles';
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
  Title,
  Subtitle,
  LinkForm,
  FlexboxGrid,
  FlexboxGridItem,
  MobileImageContent,
  Titlembile,
  Subtitlemobile,
  StyledInput,
} from '../DinabiteSignupPanel/globaldinabite.style';
import signupImage from '../../assets/images/Illustration (2).png';
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput';
import useUser from '../../hooks/useUser';
import { loginPageSchema } from '../../components/forms/formSignUp/validation';
import { findUser, checkCompanyExists } from '../../services/findUser';
import { checkAccountActivation } from '../../services/createUser';
import Modal from './modal';
import { useAuth } from '../../context/AuthContext';
import {  notificationError } from '../../lib/notification';

const TOKEN = 'access_token';
interface ActivationResponse {
  message: string;
}

const DinabiteSignin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  const { login, login2 } = useUser();
  const history = useHistory();
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalEmail, setModalEmail] = useState('');
  const { setUser, setCompanySelected, setLoginProvider } =
    useAuth();
  const isLoginPage = true; // Set to true if this is the login page

  const storage = ({
    user: apiUser,
    access_token: accessToken,
  }: {
    user: any;
    access_token: string;
  }) => {
    setUser(apiUser);
    setCompanySelected(apiUser.companies[0]);
    localStorage.setItem(TOKEN, accessToken);
    localStorage.setItem('user', JSON.stringify(apiUser)); // Store the entire user object

  };
  
  const handleCancel = () => {
    history.push('/');
    localStorage.removeItem('user');
    localStorage.removeItem(TOKEN);
  };

  const handleLogin = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    setIsLoading(true);
    const userResponse = await findUser(email);
    if (
      userResponse.data &&
      Array.isArray(userResponse.data) &&
      userResponse.data.length > 0
    ) {
      setIsLoading(false);
      const user = userResponse.data[0];
      const checkActivationResponse: ActivationResponse =
        await checkAccountActivation(email);
      if (checkActivationResponse.message === 'Account is activated') {
        const companyExists = await checkCompanyExists(email); // Make sure this line is executed correctly
        if (companyExists) {
          // If company exists, proceed with login
          await login(email, password);
          history.push('/user-panel/dashboard'); // Redirect to dashboard instead of sign-up
        } else {
          const loginResponse = await login2(email, password); // Attempt login2
          if (loginResponse) {
            const storedUser = localStorage.getItem('user');
            const storedToken = localStorage.getItem('access_token');
        
            storage(loginResponse); // Store user data and token
            history.push({
              pathname: '/sign-up',
              state: { fromLogin: true, currentStep2: 2, email },
            });
          } else {
            setIsLoading(false);
          }
        }
      } else {
        try {
          const response = await axios.post('/auth/login', { email, password });

          // Check for successful login
          if (response.status === 200) {
            storage(response.data); // Store user data and token
          }
        } catch (error) {
          // Handle 401 Unauthorized error
          if (error.response) {
            if (error.response.status === 401) {
              const message = error.response.data?.message || 'Unauthorized';
              // console.log('Login failed:', message);

              if (message.includes('verification link')) {
              
                // await sendverifyingEmail(email);
                setModalEmail(email);
                setShowModal(true);
                setModalMessage(
                  'Your account is not activated.You can send verification link again to',
                );
                setIsLoading(false);
              } else {
                notificationError({
                  title: 'Login Failed',
                  description: message,
                });
              }
            } else {
              // Handle other errors
             
              notificationError({
                title: 'Error',
                description: 'An unexpected error occurred. Please try again.',
              });
            }
          } else {
            notificationError({
              title: 'Error',
              description:
                'Network error or server is down. Please try again later.',
            });
          }
        }
        setIsLoading(false);
      }
    } else {
      // alert('User email does not exist. Please sign up first.');
      notificationError({
        title: 'Error',
        description: 'User email does not exist. Please sign up first.',
      });
      // clearStorage();
    }

    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const title = 'Login';

  return (
    <>
      <Container>
        <LeftPane>
          <StepHeaderContainer>
            <CreateAccountTitle>{title}</CreateAccountTitle>
          </StepHeaderContainer>
          <MobileImageContent src={signupImage} alt="Step 1 Image" />

          <Formik
            initialValues={initialValues}
            validationSchema={loginPageSchema}
            onSubmit={handleLogin}
            validateOnMount>
            {({
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <ContentContainer>
                  <Title>Welcome back</Title>
                  <Subtitle>Hey there! Please sign in below!</Subtitle>
                  <Titlembile>Login</Titlembile>
                  <Subtitlemobile>
                    Please enter your username and password
                  </Subtitlemobile>

                  <StyledInput
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isError={!!(touched.email && errors.email)}
                  />
                  <StyledErrorMessage>
                    {touched.email && errors.email}
                  </StyledErrorMessage>

                  <PasswordInput
                    signUp
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <StyledErrorMessage>
                    {touched.password && errors.password}
                  </StyledErrorMessage>

                  <FlexboxGrid align="middle" justify="end">
                    <FlexboxGridItem>
                      <LinkForm to="/forget-pass">Forgot Password?</LinkForm>
                    </FlexboxGridItem>
                  </FlexboxGrid>

                  <DesktopBackButtonContainer>
                    <NextButtonContainer>
                      <Button
                        type="submit"
                        disabled={isLoading || isSubmitting}>
                        {isLoading ? 'Loading...' : 'Login'}
                      </Button>
                    </NextButtonContainer>
                    <BackCancelContainer>
                      <DesktopBackButton type="button" onClick={handleCancel}>
                        Back
                      </DesktopBackButton>
                    </BackCancelContainer>
                  </DesktopBackButtonContainer>

                  <BackButtondiv>
                    <BackButton type="button" onClick={handleCancel}>
                      Back
                    </BackButton>
                    <BackButtonnext
                      type="submit"
                      disabled={isLoading || isSubmitting}>
                      {isLoading ? 'Loading...' : 'Login'}
                    </BackButtonnext>
                  </BackButtondiv>
                </ContentContainer>
              </form>
            )}
          </Formik>
        </LeftPane>

        <RightPane>
          <ImageContainer>
            <StyledImage src={signupImage} alt="Illustration" />
            <DinabiteText>
              Exciting times ahead! With Dinabite, you will be posting, managing
              messages, reviews, and promotions on all social media and
              marketing platforms—all very easily, and like a pro. <br />
              Let’s get started!
            </DinabiteText>
          </ImageContainer>
        </RightPane>
      </Container>
      {showModal && (
        <Modal
          message={modalMessage}
          email={modalEmail}
          onClose={handleCloseModal}
          showModal={showModal} 
          isLoginPage={isLoginPage} 
        />
      )}{' '}
    </>
  );
};

export default DinabiteSignin;
