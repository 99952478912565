import { Button } from 'rsuite';
import styled from 'styled-components';

export const Container = styled(Button)<{ color?: boolean }>`
  font-family: 'Inter', sans-serif;
  background: ${props =>
    props.color
      ? props.theme.colors.white
      : props.theme.colors.gradientred};

  border: 2px solid #dd2256;
  color: ${props => (props.color ? '#dd2256' : '#fff')};
  min-width: 180px;
  height: 45px;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  // Override hover styles
  transition: background 1s ease, color 1s ease;

  &:hover {
    color: ${props => (props.color ? '#fff' : '#fff')};
    background: ${props =>
      props.color
        ? props.theme.colors.gradientred
        : props.theme.colors.gradientred};

  }
  @media ${props => props.theme.breakpoints.sm} {
    margin-left: 12px; 
    height: 40px;
    margin-bottom:8px;
  }
`;
