import { Button, FlexboxGrid, Col, InputGroup } from 'rsuite';
import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  /* padding: 0 4rem 0 3rem; */
  padding-bottom: 120px;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    padding: 2rem 1rem;
  }
`;

export const HeaderContainer = styled(FlexboxGrid)`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: center;
  color: ${props => props.theme.colors.greyLetter};

  h1 {
    font-size: ${props => props.theme.fonts.bigSubtitle};
    color: ${props => props.theme.colors.black};
  }

  span {
    text-align: right;
    font-size: ${props => props.theme.fonts.paragraph};
    font-family: 'Poppins';

    @media ${props => props.theme.breakpoints.xs},
      ${props => props.theme.breakpoints.sm} {
      display: none;
    }
  }
`;

export const SaveButton = styled(Button)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.green};
  margin-right: 25px;
  padding: 13px 30px;
  border-radius: 20px;
  font-family: 'Poppins';
  font-weight: 500;
`;

export const CardRight = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 100%;
`;
export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 100px;
  width: 100%;
  border-radius: 15px;
  @media ${props => props.theme.breakpoints.xs} {
    //  width: 100%;
    // float: none;
  }
`;

export const Card = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 15px;
  padding: 30px;
  margin-top: 25px;

  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }

  h1 {
    color: ${props => props.theme.colors.black};
    font-size: 20px;
    margin-bottom: 35px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  form {
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
  }
`;
export const SelectStyle = styled(Select)`
  width: 100%;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.paragraph};
  font-family: 'Poppins';
  margin: 0px 35px 10px 10px;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-right: 0px;
  }
`;

export const ColSelect = styled(Col)`
  margin-bottom: 40px;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-bottom: 0px;
  }
`;

export const IconSelect = styled(InputGroup.Addon)`
  background-color: transparent;
  padding: 0px;
  margin: 0px;
`;

export const GroupSelect = styled(InputGroup)`
  border: 0px !important;
  padding: 0px;
  margin: 0px;
`;

export const PError = styled.p`
  text-align: right;
  color: ${props => props.theme.colors.red};
  margin-right: 35px;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-right: 0px;
  }
`;

export const BillingContainer = styled(FlexboxGrid)`
  gap: 10px;
  h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding: 0px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  padding: 10px 80px;
  background-color: ${props => props.theme.colors.white};

  h1 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
    border-radius: 12px;
  }
  h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }
  @media ${props => props.theme.breakpoints.sm} {
    flex-direction: column;
    padding: 10px;
    width: 100%;
    > * {
      flex: 1;
      margin-left: 0;
    }

    h1,
    h3 {
      margin-left: 15px;
    }
    p {
      margin-left: 0;
      margin-bottom: 4px;
    }
    div {
      margin-bottom: 8px;
    }
  }
`;

export const PriceCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  padding: 0px 5px;
  @media (max-width: 786px) {
    backgorund-color: red;
    flex-direction: column-reverse;
  }
`;

export const FeaturesCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 10px;
  background-color: ${props => props.theme.colors.white};
  padding: 15px 30px;
  width: 50%;
  border-radius: 12px;
  border: 1px solid #e5e1e1;

  h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
    margin-left: 50px;
  }
  @media ${props => props.theme.breakpoints.sm} {
    width: 100%;
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding: 0px;
    width: 100%;
  }
`;

export const PriceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.theme.colors.white};
  padding: 15px 30px;
  width: 50%;
  border-radius: 12px;
  border: 1px solid #e5e1e1;

  h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }
  @media ${props => props.theme.breakpoints.sm} {
    width: 100%;
  }
  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
  }
`;
export const ToogleContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: ${props => props.theme.colors.white};
  padding: 15px 30px;

  span {
    font-size: 12px;
  }

  .rs-btn-toggle {
    background-color: ${props =>
      props.disabled ? '' : props.theme.colors.red};
  }

  .rs-btn-toggle-checked {
    background-color: ${props => props.theme.colors.red};
  }

  h3 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding: 0px;
  }
`;
